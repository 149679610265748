const API_URL = "https://felix-utils-prod.inpwrd.net/send-contact-email"

export default async function submitForm(form) {
  const requiredFields = [
    "topic",
    "name",
    "company",
    "email",
    "country",
    "g-recaptcha-response",
  ]
  console.log("form", form)
  const formData = new FormData(form)

  const data = Object.fromEntries(formData.entries())
  if (
    requiredFields.some(field => !data.hasOwnProperty(field) || !data[field])
  ) {
    return { success: false, error: "Please fill all the required fields!" }
  }
  if (window.lintrk) {
    window.lintrk("track", { conversion_id: 7811236 })
  }
  if (window.gtag) {
    window.gtag("event", "conversion", {
      send_to: "AW-1018747664/3WTMCK7KnawZEJC24-UD",
    })

    // DV360 GTag
    window.gtag("event", "conversion", {
      allow_custom_scripts: true,
      send_to: "DC-14787405/invmedia/dv3600+standard",
    })
  }
  if (window.Inpowered) {
    new window.Inpowered.ConversionTracker().track({ conversionTypeId: "c1" })
  }
  return fetch(API_URL, {
    method: "post",
    body: JSON.stringify({
      ...data,
      url: window.location.href,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(response => response.json())
}
